import { VinCodeRequestData } from "core/typings";

export class VinCodeRequest {
  date: Date;
  url: string;
  vin: string;

  constructor(data: VinCodeRequestData) {
    this.date = data.date;
    this.url = data.url;
    this.vin = data.vin;
  }
}
