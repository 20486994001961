import {
	toastController,
	modalController,
	popoverController,
	loadingController,
	alertController,
	type ComponentProps
} from "@ionic/core";
import { Env } from "@stencil/core";

import store from "./store";
import { UserEntity } from "core/models";

import { getFunctions, httpsCallable } from "firebase/functions";
import { firebase } from "global/app";
import md5 from "md5";

export async function auth() {
	const username = localStorage.getItem("username");
	const password = localStorage.getItem("password");

	if (username && password) {
		const user = await authUser(username, password);
		if (user) {
			store.user = user;
			store.theme = user.theme;
		} else {
			logout();
		}
	} else {
		store.user = null;
		store.theme = false;
	}
}

export async function authUser(username: string, password: string) {
	const user = await UserEntity.get(username);

	if (user && password === md5(user.password + user.login)) {
		return user;
	}
	presentToastError("Wrong username and password!");
	return null;
}

export async function logout() {
	localStorage.removeItem("username");
	localStorage.removeItem("password");

	store.user = null;
	store.theme = false;
}

export function fbCallableFunction(name: string) {
	const functions = getFunctions(firebase, Env.functionsRegion);
	return httpsCallable(functions, name);
}

export function back() {
	const routerEl = document.querySelector("ion-router");
	routerEl.back();
}

export function redirect(url: string) {
	const routerEl = document.querySelector("ion-router");
	routerEl.push(url);
}

export async function presentToastTrue(text: string) {
	const toast = await toastController.create({
		message: text,
		color: "primary",
		position: "top",
		duration: 2000,
	});
	return toast.present();
}

export async function presentToastError(text: string) {
	const toast = await toastController.create({
		message: text,
		color: "danger",
		position: "top",
		duration: 2000,
	});
	return toast.present();
}
export async function presentAlertConfirm(
	message: string,
	okFunc: () => void,
	noFunc?: () => void,
) {
	const alert = await alertController.create({
		header: "Confirmation",
		message: message,
		buttons: [
			{
				text: "Yes",
				handler: () => {
					okFunc();
				},
			},
			{
				text: "Cancel",
				role: "cancel",
				handler: () => {
					if (noFunc) {
						noFunc();
					}
				},
			},
		],
	});
	return await alert.present();
}

export async function presentModal(
	componentName: string,
	componentProp?: ComponentProps,
	cssClass?: string,
) {
	const modal = await modalController.create({
		component: componentName,
		cssClass: cssClass,
		componentProps: { ...componentProp },
	});
	await modal.present();

	return modal;
}

export const presentPopover = async (
	componentName: string,
	componentProp?: ComponentProps,
	cssClass?: string,
	event?: Event,
) => {
	const popover = await popoverController.create({
		component: componentName,
		componentProps: {
			...componentProp,
		},
		cssClass: cssClass,
		event: event,
		reference: "event",
	});
	await popover.present();
	return popover;
};

export function circumcisionURl(url: string): string {
	const saveMass = url.split("/");
	saveMass.shift();
	saveMass.shift();
	saveMass.shift();
	const localUrl = `/${saveMass.join("/")}`;
	return localUrl;
}

export async function presentLoading(message: string) {
	const loading = await loadingController.create({
		message: message,
	});
	return loading;
}

export async function presentAgLoading() {
	const loading = await popoverController.create({
		component: "ag-present-loading",
		cssClass: "ag-present-loading",
		translucent: true,
		backdropDismiss: false,
		animated: true,
		alignment: "center",
	});
	await loading.present();
	return loading;
}
