import { DocumentFirestore } from "core/models";
import type { HistoryVinCodeRequestsDocumentData, HistoryVinCodeRequest } from "core/typings";

export class HistoryVinCodeRequestsDocument extends DocumentFirestore<HistoryVinCodeRequestsDocumentData> {
  static readonly collPath: string = "history_vin_code_requests";

  constructor(data?: HistoryVinCodeRequestsDocumentData) {
    super(data);

    if (data) {
      for (let key in data) {
        this[key] = data[key];
      }
    }
  }

  get historyVinCodeRequest(): HistoryVinCodeRequest {
    const data = this.toData();
    delete data["id"];

    return data as any;
  }
}
