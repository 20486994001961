import { WinСheckData } from "core/typings";

export class WinСheck {
  quantity: number;
  insight: boolean;
  used: number;

  constructor(data?: WinСheckData) {
    if (data) {
      this.quantity = data.quantity;
      this.insight = data.insight;
      this.used = data.used;
    } else {
      this.quantity = 0;
      this.insight = false;
      this.used = 0;
    }
  }
}
