import { BaseFirestore } from "core/models";

import {
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import type { WhereFilterOp, FieldPath, Unsubscribe } from "firebase/firestore";
import type { DocumentFirestoreData, BaseConstructorClassThis } from "core/typings";

export class DocumentFirestore<T extends DocumentFirestoreData = DocumentFirestoreData> extends BaseFirestore<T> {
  static async create<T extends DocumentFirestore>(this: BaseConstructorClassThis<T>, id: string, data?: any): Promise<T> {
    const document = new this(data);

    document.id = id;
    const documentData = document.toData();
    const docRef = doc(this.collRef, id).withConverter(this.converter());
    return setDoc(docRef, documentData)
      .then(() => {
        return document;
      })
      .catch((err) => {
        console.log(err);
        return document;
      });
  }

  static async get<T extends DocumentFirestore>(this: BaseConstructorClassThis<T>, id: string): Promise<T> {
    let docRef = doc(this.collRef, id).withConverter(this.converter());

    return getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          const document = doc.data();
          document.id = id;

          return document;
        } else {
          return null;
        }
      })
      .catch((err) => {
        console.log(`Error getting document with id - ${id} : ${err}`);
        return null;
      });
  }

  static async getAll<T extends DocumentFirestore>(this: BaseConstructorClassThis<T>): Promise<T[]> {
    return getDocs(this.collRef)
      .then((querySnapshot) => {
        const documents = [];

        querySnapshot.forEach((doc) => {
          const document = doc.data();
          document.id = doc.id;

          documents.push(document);
        });

        return documents;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  }

  static async getAllQuery<T extends DocumentFirestore>(
    this: BaseConstructorClassThis<T>,
    fieldPath: string | FieldPath,
    opStr: WhereFilterOp,
    value: unknown
  ): Promise<T[]> {
    const q = query(this.collRef, where(fieldPath, opStr, value));

    return getDocs(q)
      .then((querySnapshot) => {
        const documents = [];

        querySnapshot.forEach((doc) => {
          const document = doc.data();
          document.id = doc.id;

          documents.push(document);
        });

        return documents;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  }

  static async delete(id: string): Promise<string> {
    const docRef = doc(this.collRef, id);
    return deleteDoc(docRef)
      .then(() => {
        return id;
      })
      .catch((err) => {
        console.log(err);
        return id;
      });
  }

  async create(): Promise<string> {
    const cls = this.getClass();

    const docRef = doc(cls.collRef, this.id);
    const documentData = this.toData();

    delete documentData["id"];

    return setDoc(docRef, documentData)
      .then(() => {
        return this.id;
      })
      .catch((err) => {
        console.log(err);
        return this.id;
      });
  }

  async update(): Promise<string> {
    const cls = this.getClass();

    const docRef = doc(cls.collRef, this.id);
    const documentData = this.toData();

    delete documentData["id"];

    return updateDoc(docRef, documentData as any)
      .then(() => {
        return this.id;
      })
      .catch((err) => {
        console.log(err);
        return this.id;
      });
  }

  async delete(): Promise<string> {
    const cls = this.getClass();

    const docRef = doc(cls.collRef, this.id);

    return deleteDoc(docRef)
      .then(() => {
        return this.id;
      })
      .catch((err) => {
        console.log(err);
        return this.id;
      });
  }

  subscribeDocumentChanged(callback: (data: any) => void): Unsubscribe {
    const cls = this.getClass();
    const docRef = doc(cls.collRef, this.id).withConverter(cls.converter());
    const unsubscribe = onSnapshot(
      docRef,
      (doc) => {
        const documentNew = doc.data();
        if (documentNew) {
          documentNew.id = this.id;

          callback(documentNew);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return unsubscribe;
  }

  unsubscribeDocumentChanged(unsubscribe: Unsubscribe): void {
    unsubscribe();
  }
}
