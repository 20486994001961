import { createStore } from "@stencil/store";
import { presentModal } from "global/helpers";
import type { UserEntity } from "core/models";

const { state, onChange } = createStore<{ user: UserEntity; theme: boolean }>({
  user: null,
  theme: null,
});

onChange("user", (user) => {
  if (user)
    if (!user.agreements) {
      presentModal("agreements-modal", {}, "agreements-modal");
    }
});

onChange("theme", (shouldAdd) => {
  document.documentElement.classList.toggle("ion-palette-dark", shouldAdd);
});

export default state;
