import { collection } from "firebase/firestore";
import type {
  DocumentData,
  SnapshotOptions,
  CollectionReference,
  QueryDocumentSnapshot,
  FirestoreDataConverter,
} from "firebase/firestore";

import { firestore } from "global/app";
import type { BaseFirestoreData } from "core/typings";

export class BaseFirestore<T extends BaseFirestoreData = BaseFirestoreData> {
  static onReady: Promise<void> = null;
  static readonly collPath: string;
  static collRef: CollectionReference<DocumentData, DocumentData>;

  id: string;

  static async init(ancestors: boolean = false) {
    if (ancestors) {
      this.collRef = collection(firestore, this.collPath).withConverter(
        this.converter()
      );
    } else {
      if (!this.onReady) {
        this.onReady = new Promise((resolve) => {
          this.collRef = collection(firestore, this.collPath).withConverter(
            this.converter()
          );
          resolve()
        });
      }
      await this.onReady;
    }
  }

  static converter(): FirestoreDataConverter<DocumentData, DocumentData> {
    return {
      fromFirestore: (snapshot, options) =>
        this.fromFirestore(snapshot, options),
      toFirestore: (entityData) => this.toFirestore(entityData),
    };
  }

  static fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    this.convertTimestampsFromFirestore(data);

    const entity = new this(data as BaseFirestoreData);

    return entity;
  }

  static convertTimestampsFromFirestore(_data: DocumentData) {
    //
  }

  static toFirestore(entityData): DocumentData {
    delete entityData["id"];
    return entityData;
  }

  constructor(data?: T) {
    if (data) {
      this.id = data.id;
    } else {
      this.id = "";
    }
  }

  getClass() {
    return this.constructor as any;
  }

  clone(): this {
    const cls = this.getClass();
    const newEntity = new cls(this);
    newEntity.id = this.id;
    return newEntity;
  }

  toData(): T {
    return structuredClone(this.clone()) as any;
  }
}
