import "@ionic/core";
import { auth } from "global/helpers";
import { Env } from "@stencil/core";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = JSON.parse(Env.firebaseConfig);

export const firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebase);
export const storage = getStorage(firebase);

import { DeliveryEntity, UserEntity, CalculatorIndicesEntity} from "core/models";

export default async () => {
  await DeliveryEntity.init();
  await UserEntity.init();
  await CalculatorIndicesEntity.init();

  await auth();
};
