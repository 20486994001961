import { DocumentFirestore, VinCodeRequest } from "core/models";
import type { VinCodeRequestDocumentData } from "core/typings";

export class VinCodeRequestDocument extends DocumentFirestore<VinCodeRequestDocumentData> {
  static readonly collPath: string = "vin_code_requests";

  items: VinCodeRequest[];

  constructor(data?: VinCodeRequestDocumentData) {
    super(data);

    if (data) {
      this.items = data.items.map((data) => new VinCodeRequest(data));
    } else {
      this.items = [];
    }
  }

  static convertTimestampsFromFirestore(data) {
    super.convertTimestampsFromFirestore(data);

    data.items.forEach((item) => (item.date = item.date.toDate()));
  }
}
