import type { Urls, DeliveryEntityData } from "core/typings";
import { EntityFirestore } from "core/models";

export class DeliveryEntity extends EntityFirestore<DeliveryEntityData> {
	static readonly collPath = "deliveries";
	static readonly usesSubscription: boolean = true;

	client: string;
	number: string;
	imageUrls: Urls;
	manager: string;
	services: number;
	prepaidExpense: number;
	payment: number;
	balanceResidue: number;
	feasibilityStudy: number;
	shipping: number;
	storageFee: number;
	otherFee: number;
	purchaseAmount: number;
	renovationCity: string;
	date: Date;
	status: string;
	dateOfDelivery: Date;
	warehouseDate: Date;
	container: string;
	model: string;
	vin: string;
	auction: string;
	portLoading: string;
	portDischarge: string;
	lot: string;

	createDate: Date;

	constructor(data?: DeliveryEntityData) {
		super(data);

		if (data) {
			this.client = data.client;
			this.number = data.number;
			this.imageUrls = data.imageUrls;
			this.manager = data.manager;

			this.services = data.services;
			this.prepaidExpense = data.prepaidExpense;
			this.payment = data.payment;
			this.balanceResidue = data.balanceResidue;
			this.feasibilityStudy = data.feasibilityStudy;
			this.shipping = data.shipping;
			this.storageFee = data.storageFee;
			this.otherFee = data.otherFee;
			this.purchaseAmount = data.purchaseAmount;

			this.renovationCity = data.renovationCity;
			this.date = new Date(data.date);

			this.status = data.status;
			this.dateOfDelivery = data.dateOfDelivery
				? new Date(data.dateOfDelivery)
				: null;
			this.warehouseDate = data.warehouseDate
				? new Date(data.warehouseDate)
				: null;
			this.container = data.container;

			this.model = data.model;

			this.vin = data.vin;
			this.auction = data.auction;
			this.portLoading = data.portLoading;
			this.portDischarge = data.portDischarge;
			this.lot = data.lot;

			this.createDate = new Date(data.createDate);
		} else {
			this.client = "";
			this.number = "";
			this.imageUrls = [];
			this.manager = "";

			this.services = 0;
			this.prepaidExpense = 0;
			this.payment = 0;
			this.balanceResidue = 0;
			this.feasibilityStudy = 0;
			this.shipping = 0;
			this.storageFee = 0;
			this.otherFee = 0;
			this.purchaseAmount = 0;

			this.renovationCity = "";
			this.date = new Date();

			this.status = "";
			this.dateOfDelivery = null;
			this.warehouseDate = null;
			this.container = "";

			this.model = "";

			this.vin = "";
			this.auction = "";
			this.portLoading = "";
			this.portDischarge = "";
			this.lot = "";

			this.createDate = new Date();
		}
	}

	static sortCachedItems() {
		this.cachedItems.sort((a, b) => {
			if (a.createDate < b.createDate) {
				return 1;
			}
			return -1;
		});
	}

	static convertTimestampsFromFirestore(data) {
		super.convertTimestampsFromFirestore(data);

		if (data.dateOfDelivery) {
			data.dateOfDelivery = data.dateOfDelivery.toDate();
		} else {
			data.dateOfDelivery = null;
		}

		if (data.warehouseDate) {
			data.warehouseDate = data.warehouseDate.toDate();
		} else {
			data.warehouseDate = null;
		}

		data.date = data.date.toDate();
		data.createDate = data.createDate.toDate();
	}
}
