import { EntityFirestore, WinСheck } from "core/models";
import type { UserEntityData } from "core/typings";

export class UserEntity extends EntityFirestore<UserEntityData> {
  static readonly collPath: string = "users";
  static readonly usesSubscription: boolean = true;

  agreements: boolean;
  createdAt: Date;
  createdBy: string;
  email: string;
  login: string;
  name: string;
  password: string;
  phoneNumber: string;
  createdUsers: string[];
  calculateNumber: number;
  role: "admin" | "manager" | "client" | "managerPlus";
  wineСheck: WinСheck;
  enableCalculation: boolean;
  enableInvoice: boolean;
  enableTable: boolean;
  enableCalculationExtended: boolean;
  theme: boolean;

  constructor(data?: UserEntityData) {
    super(data);

    if (data) {
      this.agreements = data.agreements;
      this.createdAt = new Date(data.createdAt);
      this.createdBy = data.createdBy;
      this.email = data.email;
      this.login = data.login;
      this.name = data.name;
      this.password = data.password;
      this.phoneNumber = data.phoneNumber;
      this.createdUsers = data.createdUsers;
      this.calculateNumber = data.calculateNumber;
      this.role = data.role;
      this.wineСheck = new WinСheck(data.wineСheck);
      this.enableCalculation = data.enableCalculation;
      this.enableInvoice = data.enableInvoice;
      this.enableTable = data.enableTable;
      this.enableCalculationExtended = data.enableCalculationExtended;
      this.theme = data.theme;
    } else {
      this.agreements = false;
      this.createdAt = new Date();
      this.createdBy = null;
      this.email = "";
      this.login = "";
      this.name = "";
      this.password = "";
      this.phoneNumber = "";
      this.createdUsers = [];
      this.calculateNumber = 1;
      this.role = "client";
      this.wineСheck = new WinСheck();
      this.enableCalculation = false;
      this.enableInvoice = false;
      this.enableTable = false;
      this.enableCalculationExtended = false;
      this.theme = false;
    }
  }

  static sortCachedItems() {
    this.cachedItems.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  static convertTimestampsFromFirestore(data) {
    super.convertTimestampsFromFirestore(data);

    data.createdAt =  data.createdAt.toDate();
  }
}
