import { DocumentFirestore } from "./document.firestore";
import type {
	EntityFirestoreData,
	BaseConstructorClassThis,
} from "core/typings";

import { onSnapshot, getDocs } from "firebase/firestore";
import type { Unsubscribe } from "firebase/firestore";
import { Subject } from "rxjs";

export class EntityFirestore<
	T extends EntityFirestoreData = EntityFirestoreData,
> extends DocumentFirestore<T> {
	static cachedItems: any[] = null;
	static readonly usesSubscription: boolean = false;

	static observableEntityAdded: Subject<unknown>;
	static observableEntityModified: Subject<unknown>;
	static observableEntityRemoved: Subject<unknown>;
	static subscriptionEntityActions: Unsubscribe;

	static async init(ancestors: boolean = false) {
		if (ancestors) {
			await super.init(true);

			await this.fetchInitialData();
			this.subscribe();
		} else {
			if (!this.onReady) {
				this.onReady = new Promise(async (resolve) => {
					await super.init(true);

					await this.fetchInitialData();
					this.subscribe();
					resolve();
				});
			}
			await this.onReady;
		}
	}

	static subscribe(): Unsubscribe {
		if (this.usesSubscription) {
			if (!this.observableEntityAdded) {
				this.observableEntityAdded = new Subject();
			}
			if (!this.observableEntityModified) {
				this.observableEntityModified = new Subject();
			}
			if (!this.observableEntityRemoved) {
				this.observableEntityRemoved = new Subject();
			}

			if (!this.subscriptionEntityActions) {
				this.subscriptionEntityActions = onSnapshot(
					this.collRef,
					(snapshot) => {
						snapshot.docChanges().forEach((change) => {
							switch (change.type) {
								case "added": {
									const entity = change["doc"].data();
									const id = change["doc"].id;
									entity.id = id;
									const index = this.cachedItems.findIndex(
										(el) => el.id === id,
									);
									if (index == -1) {
										this.cachedItems.push(entity);
										this.sortCachedItems();
										this.observableEntityAdded.next(entity);
									}

									break;
								}
								case "modified": {
									const entity = change["doc"].data();
									const id = change["doc"].id;
									entity.id = id;

									const index = this.cachedItems.findIndex(
										(el) => el.id === id,
									);
									if (index > -1) {
										this.cachedItems[index] = entity;
										this.observableEntityModified.next(entity);
									} else {
										this.cachedItems.push(entity);
										this.observableEntityAdded.next(entity);
									}

									break;
								}
								case "removed": {
									const id = change["doc"].id;
									this.cachedItems = this.cachedItems.filter(
										(entity) => entity.id !== id,
									);
									this.observableEntityRemoved.next(change["doc"]);
								}
							}
						});
					},
				);
				return this.subscriptionEntityActions;
			}
		}
	}

	static async fetchInitialData() {
		if (this.cachedItems !== null) return null;

		this.cachedItems = [];

		return await getDocs(this.collRef)
			.then((docs) => {
				if (!this.usesSubscription) {
					docs.forEach((doc) => {
						const entity = doc.data();
						entity.id = doc.id;
						this.cachedItems.push(entity);
					});
				}
			})
			.then(() => {
				this.sortCachedItems();
			})
			.catch((err) => {
				console.log(`Error getting ${this.name} list: ${err}`);
			});
	}

	static getCachedItem<T extends EntityFirestore>(
		this: BaseConstructorClassThis<T>,
		field: string,
		value: any,
	): T {
		return this.cachedItems.find((entity) => entity[field] === value);
	}

	static getCachedItems<T extends EntityFirestore>(
		this: BaseConstructorClassThis<T>,
		field: string,
		value: any,
	): T[] {
		return this.cachedItems.filter((entity) => entity[field] === value);
	}

	static sortCachedItems() {}
}
